<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-card
            max-width="600"
            width="600"
            class="pa-5 text-left"
          >
            <h3 class="text-h5">
              Password Reset
            </h3>

            <template v-if="success">
              <v-alert
                border="top"
                type="success"
                class="my-2"
              >
                We've emailed you instructions for setting your password. You should be
                receiving them shortly.
                <br>
                If you don't receive an email, please make sure you've entered the
                correct email address, and check your spam folder.
              </v-alert>

              <div class="text-right">
                <v-btn @click="$router.go(-1)">
                  OK
                </v-btn>
              </div>
            </template>
            <template v-else>
              <form @submit.prevent="submit">
                <p class="mt-3">
                  Lost your password or need to set one up? Enter your email address below,
                  and we'll email instructions for setting a new one.
                </p>

                <v-alert
                  v-if="errors.non_field_errors"
                  dense
                  border="left"
                  type="warning"
                >
                  {{ errors.non_field_errors[0] }}
                </v-alert>

                <v-text-field
                  v-model="formFields.email"
                  label="Email"
                  type="email"
                  :error-messages="errors.email"
                  required
                />

                <div class="text-right mt-2">
                  <v-btn
                    class="mr-2"
                    plain
                    @click.prevent="$router.go(-1)"
                  >
                    cancel
                  </v-btn>

                  <v-btn
                    type="submit"
                    color="primary"
                    :disabled="submitting"
                  >
                    submit
                    <v-progress-circular
                      v-if="submitting"
                      size="20"
                      width="2"
                      indeterminate
                      class="ml-2"
                    />
                  </v-btn>
                </div>
              </form>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitting: false,
      submitted: false,
      errors: {},
      success: false,
      formFields: {
        email: '',
      },
    };
  },
  metaInfo: {
    title: 'Password Reset',
  },
  methods: {
    async submit() {
      this.errors = {};
      this.submitting = true;

      const responseData = await this.api({
        url: 'users/password_reset/',
        method: 'POST',
        json: this.formFields,
      });

      this.submitting = false;
      this.submitted = true;

      if (responseData.status === 200) {
        this.success = true;
      } else if (responseData.status === 400) {
        this.errors = responseData.body;
      } else {
        this.errors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
};
</script>
